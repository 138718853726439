import { TelemetryEnrichmentInitializer } from './telemetry-enrichment-initializer';
import { isPageViewTelemetryItem } from './telemetry-item-predicate';
import { ActivatedRoute } from '@angular/router';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { getLastRoute, getNormalizedRoutePath } from './route-functions';
import { Injectable } from '@angular/core';

/**
 * Convenience base class to create a telemetry initializer for that will enrich
 * page view telemetry item with additional data before it's sent to app insights.
 * To register your telemetry initializer use `TELEMETRY_INITIALIZER` as below
 * @example
 * providers: [{
 *   provide: TELEMETRY_INITIALIZER,
 *   useClass: YourTelemetryEnrichmentInitializer,
 *   multi: true
 * }]
 */
@Injectable()
export abstract class PageViewTelemetryEnrichmentInitializer extends TelemetryEnrichmentInitializer {
  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }

  protected get requiredRoutePath(): string | undefined {
    return undefined;
  }

  protected override match(item: ITelemetryItem): boolean {
    if (!isPageViewTelemetryItem(item)) return false;
    if (this.requiredRoutePath === undefined) return true;

    return this.requiredRoutePath === this.currentRoutePath;
  }

  protected get currentRoutePath() {
    return getNormalizedRoutePath(this.currentRoute.pathFromRoot);
  }

  protected get currentRoute() {
    return getLastRoute(this.activatedRoute.snapshot);
  }
}
