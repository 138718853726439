import { APP_INITIALIZER, Provider } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

export function appInsightsInitializer(appInsights: AppInsightsService) {
  return () => {
    if (appInsights.config.autoRun) {
      appInsights.init();
    }
  };
}

export const AppInsightsInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: appInsightsInitializer,
  multi: true,
  deps: [AppInsightsService]
};
