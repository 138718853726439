import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializerFunc } from './models';

export const tagTelemetryInitializer =
  (key: string, value: string): TelemetryInitializerFunc =>
  (item: ITelemetryItem) => {
    if (!item.tags) {
      item.tags = [];
    }

    item.tags[key] = value;
  };
