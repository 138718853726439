import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  DrawerContents,
  DrawerItem,
  DrawerLink,
  drawerContentsFromMenuItem,
  isDrawer,
  isSection,
  nullDrawerItem
} from '../models';
import { MainNavService } from '../services/main-nav.service';

@Component({
  selector: 'mri-shared-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .menu-header {
        padding: 16px 16px 0;
        color: black;
      }
    `
  ]
})
export class NavDrawerComponent {
  isOpen = false;
  activeItem: DrawerContents = nullDrawerItem;

  constructor(
    private mainNavService: MainNavService,
    private router: Router
  ) {
    this.mainNavService.activeItem$
      .pipe(
        map(item => (item && isDrawer(item) ? drawerContentsFromMenuItem(item) : null)),
        map(activeItem => {
          this.isOpen = activeItem !== null;
          this.activeItem = activeItem ?? nullDrawerItem;
        })
      )
      .subscribe();
  }

  close() {
    this.isOpen = false;
    this.mainNavService.activate(null);
  }

  isSection(item: DrawerItem) {
    return isSection(item);
  }

  isExternalLink(item: DrawerLink<object>) {
    return item.isExternalLink;
  }

  routeToUrl(item: DrawerLink<object>) {
    if (!this.isExternalLink(item)) {
      this.router.navigateByUrl(item.url);
    } else {
      window.open(item.url, '_blank');
    }
    this.close();
  }
}
