import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppInsightsConfig } from './app-insights-config';
import { AppInsightsInitializerProvider } from './app-insights-initializer.provider';

@NgModule({})
export class AngularAppInsightsModule {
  static forRoot(config: AppInsightsConfig | null = null): ModuleWithProviders<AngularAppInsightsModule> {
    return {
      ngModule: AngularAppInsightsModule,
      providers: [config ? { provide: AppInsightsConfig, useValue: config } : [], AppInsightsInitializerProvider]
    };
  }
}
