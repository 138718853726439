import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularAppInsightsModule } from '@mri-platform/angular-app-insights';
import { AgBarCloudRoleTelemetryInitializerProvider } from './ag-bar-cloud-role-telemetry-initializer';
import { AppInsightsErrorAppenderProvider } from './app-insights-appender.provider';
import { AppInsightsConfigProvider } from './app-insights-config.provider';

@NgModule({
  imports: [CommonModule, AngularAppInsightsModule.forRoot()],
  providers: [AppInsightsErrorAppenderProvider, AppInsightsConfigProvider, AgBarCloudRoleTelemetryInitializerProvider]
})
export class AppInsightsIntegrationModule {}
