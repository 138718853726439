import { Injectable } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryEnrichmentInitializer } from './telemetry-enrichment-initializer';
import { isAjaxDependencyTelemetryItem, isPageViewTelemetryItem } from './telemetry-item-predicate';

@Injectable({ providedIn: 'root' })
export class NetworkInfoTelemetryInitializer extends TelemetryEnrichmentInitializer {
  protected match(item: ITelemetryItem) {
    return isPageViewTelemetryItem(item) || isAjaxDependencyTelemetryItem(item);
  }

  protected getData(_item: ITelemetryItem) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const connection = (navigator as any).connection;
    return {
      netEffectiveType: connection.effectiveType,
      netDownlink: connection.downlink,
      netRtt: connection.rtt
    };
  }
}
