import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializerFunc } from './models';
import { isAjaxDependencyTelemetryItem } from './telemetry-item-predicate';

const parseContentLengthHeaderValue = (headers: { [key: string]: string }) =>
  parseInt(headers['content-length'], 10) || undefined;

export const contentTypeResponseHeaderTelemetryInitializer: TelemetryInitializerFunc = (item: ITelemetryItem) => {
  if (!isAjaxDependencyTelemetryItem(item) || item.baseData?.properties?.responseHeaders == null) return;

  const length = parseContentLengthHeaderValue(item.baseData.properties.responseHeaders);
  if (length !== undefined) {
    item.baseData.properties.responseLength = length;
    // todo: consider adding rspLengthBucket to make log anlaysis easier
  }
};
