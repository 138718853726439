import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ElementRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { AngularResourceAuthzModule } from '@mri-platform/angular-resource-authz';
import { AppClientDataService, ClientsStateModule } from '@mri-platform/import-export/clients-state';
import { CommonStateModule, UserPreferenceResolver } from '@mri-platform/import-export/common-state';
import { CommonUiModule } from '@mri-platform/import-export/common-ui';
import { AppRoutes, TokenErrorHandlerProvider, ieerrorEventChannelProvider } from '@mri-platform/import-export/core';
import { JobStateModule } from '@mri-platform/import-export/jobs-state';
import { MappersStateModule } from '@mri-platform/import-export/mappers-state';
import { IEShellModule, ShellComponent } from '@mri-platform/import-export/shell';
import {
  UserEnrollmentCallbackGuard,
  UserEnrollmentConfigurationModule,
  UserEnrollmentGuard
} from '@mri-platform/import-export/user-enrollment-configuration';
import {
  AppCoreIntegrationModule,
  AppUserPermissionService,
  ImpersonateClientSwitchService
} from '@mri-platform/shared/app-core-integration';
import { AppInsightsIntegrationModule } from '@mri-platform/shared/app-insights-integration';
import { AppNgrxDataIntegrationModule } from '@mri-platform/shared/app-ngrx-data-integration';
import { NavigationModule, ViewStateModule } from '@mri-platform/shared/app-ngrx-integration';
import {
  ClientDataService,
  ClientSwitchService,
  FAKE_DB_COLLECTIONS_TOKEN,
  SharedCoreModule,
  environment
} from '@mri-platform/shared/core';
import {
  ClientSwitchListPageComponent,
  ClientsResolver,
  UnhandledErrorPageComponent
} from '@mri-platform/shared/shell';
import { AuthGuard, UserPermissionService } from '@mri/angular-wfe-proxy-oidc';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
import { AppComponent } from './containers/app.component';
import { UserEnrollmentPageComponent } from './containers/user-enrollment-page.component';
import { isAuthzAppUserPredicateProvider } from './integration';
import { ROOT_REDUCERS } from './reducers';
import { devDb } from './testing/dev-dataset';
import { testDb } from './testing/test-dataset';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          canActivate: [AuthGuard],
          component: ShellComponent,
          resolve: {
            clients: ClientsResolver,
            userPreference: UserPreferenceResolver
          },
          children: [
            AppRoutes.Enroll.applyTo({
              canActivate: [UserEnrollmentGuard],
              component: UserEnrollmentPageComponent
            }),
            AppRoutes.Callback.applyTo({
              canActivate: [UserEnrollmentCallbackGuard],
              component: UserEnrollmentPageComponent
            }),
            {
              path: AppRoutes.ImportExportJobs.path,
              loadChildren: () =>
                import('@mri-platform/import-export/jobs').then(module => module.ImportExportJobsModule)
            },
            {
              path: AppRoutes.PlatformManagement.path,
              loadChildren: () =>
                import('@mri-platform/import-export/platforms-management').then(
                  module => module.PlatformsManagementModule
                )
            },
            {
              path: AppRoutes.ClientManagement.path,
              loadChildren: () =>
                import('@mri-platform/import-export/client-management').then(module => module.ClientManagementModule)
            },
            {
              path: AppRoutes.MappingConnections.path,
              loadChildren: () =>
                import('@mri-platform/import-export/connection-management').then(
                  module => module.ConnectionManagementModule
                )
            },
            {
              path: AppRoutes.UserSecuritySettings.path,
              loadChildren: () => import('@mri-platform/import-export/admin').then(module => module.AdminModule)
            },
            {
              path: AppRoutes.MappingManager.path,
              loadChildren: () =>
                import('@mri-platform/import-export/mapping-management').then(module => module.MappingManagementModule)
            },
            {
              path: AppRoutes.RecentImportExport.path,
              loadChildren: () =>
                import('@mri-platform/import-export/recent-job-logs').then(module => module.RecentJobsModule)
            },
            AppRoutes.Error.applyTo({ component: UnhandledErrorPageComponent }),
            AppRoutes.SwitchClient.applyTo({
              component: ClientSwitchListPageComponent,
              outlet: 'drawer',
              resolve: {
                clients: ClientsResolver
              }
            }),
            { path: '', redirectTo: AppRoutes.RecentImportExport.path, pathMatch: 'full' }
          ]
        }
      ],
      {
        initialNavigation: 'enabledBlocking',
        preloadingStrategy: PreloadAllModules
      }
    ),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal
    }),
    CommonStateModule,
    AppCoreIntegrationModule.forRoot(),
    AppNgrxDataIntegrationModule.forRoot(),
    ViewStateModule,
    NavigationModule,
    IEShellModule,
    MappersStateModule,
    JobStateModule,
    ClientsStateModule,
    UserEnrollmentConfigurationModule,
    BrowserAnimationsModule,
    CommonUiModule,
    AngularResourceAuthzModule,
    AppInsightsIntegrationModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    SharedCoreModule.forRoot({ useFeatureFlags: false }).providers ?? [],
    TokenErrorHandlerProvider,
    ieerrorEventChannelProvider,
    {
      //https://www.telerik.com/kendo-angular-ui/components/notification/api/NOTIFICATION_CONTAINER/
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => ({ nativeElement: document.body }) as ElementRef
    },
    { provide: UserPermissionService, useClass: AppUserPermissionService },
    { provide: ClientDataService, useClass: AppClientDataService },
    { provide: ClientSwitchService, useClass: ImpersonateClientSwitchService },
    isAuthzAppUserPredicateProvider,
    {
      provide: FAKE_DB_COLLECTIONS_TOKEN,
      multi: true,
      useValue: environment.dev ? devDb : environment.integrationTest ? testDb : {}
    }
  ]
})
export class AppModule {}
