import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  template: `
    <mri-shared-page-header pageName="Unexpected Error"></mri-shared-page-header>
    <p class="mri-heading mri-heading--3 mri-ml-medium">
      Sorry there was a unexpected problem. Please try and refresh the browser
    </p>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnhandledErrorPageComponent {
  @HostBinding('attr.data-testid') testId = 'UnhandledErrorPageComponent';
}
