import trimEnd from 'lodash-es/trimEnd';

export interface AppGatewayConfigData {
  /**
   * Base url where the app-gateway web component script is hosted
   *
   * EG: 'https://qa-agweb-rel.redmz.mrisoftware.com'
   */
  baseWebUrl: string;
  /**
   * Base url of the AG API that the AG Bar component will fetch/save it's data
   *
   * EG: 'https://qa-agapi-rel.redmz.mrisoftware.com'
   */
  baseApiUrl: string;
  /**
   * Training academy url
   */
  trainingAcademyUrl: string;
  /**
   * Hint as to whether fetch requests from AG API should be included in app insights or no
   *
   * Note: this setting value will have no affect unless you have added app insights to your angular app
   * AND you use this setting to instruct app insights to track AG API requests
   *
   * @default false
   */
  enableAppInsights?: boolean;
  /**
   * Should AG Bar navigation be intercepted or no?
   *
   * Set this to true and then listen to the navigation events emitted by the bar by binding to the
   * {@link AgBarComponent.barNavigation} or subscribing to the {@link AgBarNotificationService.navigation$} observable.
   *
   * Typically you will want to do this so as to:
   * 1. prompt the user asking them to confirm the navigation when the page is dirty
   * 2. stop the AG Bar logging out itself from welcome, and to instead run the SPA's own logout logic
   *
   * @default false
   */
  interceptNavigation?: boolean;
}

const defaults: AppGatewayConfigData = {
  interceptNavigation: false,
  baseWebUrl: '',
  baseApiUrl: '',
  trainingAcademyUrl: '',
  enableAppInsights: false
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type,@typescript-eslint/no-empty-interface
export interface AppGatewayConfig extends AppGatewayConfigData {}

export class AppGatewayConfig {
  get apiDomain() {
    return this.baseApiUrl ? new URL(this.baseApiUrl).hostname : 'localhost';
  }

  get webUrl() {
    return this.IsDeveloperServer ? this.baseWebUrl : `${this.baseWebUrl}/default`;
  }

  private get IsDeveloperServer() {
    return this.apiDomain.includes('local');
  }

  constructor(json?: AppGatewayConfigData) {
    json = json ?? defaults;
    const baseApiUrl = trimEnd(json.baseApiUrl, '/').trim();
    const baseWebUrl = trimEnd(json.baseWebUrl, '/').trim();
    Object.assign(this, defaults, json, { baseApiUrl, baseWebUrl });
  }
}
