import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, map, take } from 'rxjs';
import { DrawerLink, isAskAgoraComponent, isDrawer, isLink, isModal, MenuItem } from '../models';

@Injectable({ providedIn: 'root' })
export class MainNavService {
  private menuItemsSubject = new BehaviorSubject<MenuItem[]>([]);
  set menuItems(items: MenuItem[]) {
    if (items) {
      this.menuItemsSubject.next(items);
    }
  }
  get menuItems$() {
    return this.menuItemsSubject.asObservable();
  }

  private activeItemSubject = new BehaviorSubject<MenuItem | null>(null);
  private activePageSubject = new BehaviorSubject<MenuItem | null>(null);
  private clientIdSubject = new BehaviorSubject<string | null>(null);

  activate(item: MenuItem | null) {
    let activeItem: MenuItem | null = item;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let sideEffect: () => void = () => {};
    if (item) {
      if (isLink(item)) {
        if (item.opener) {
          sideEffect = () => item.opener?.(item.url);
        } else {
          let newUrl = item.url;
          const menuToggle = this.containsQueryParam(this.router.url) ? '&menuToggle=true' : '?menuToggle=true';
          if (item.supportsToggle) {
            if (this.router.url.includes(item.url)) {
              newUrl = this.router.url.includes(menuToggle)
                ? this.router.url.replace(menuToggle, '')
                : this.router.url.concat(menuToggle);
            }
          }
          sideEffect = () => this.router.navigateByUrl(newUrl);
        }
      } else if (isDrawer(item) || isAskAgoraComponent(item)) {
        // if drawer menu item was clicked again, it should deactivate the menu item and close the drawer
        activeItem = this.activeItem === item ? null : item;
      } else if (isModal(item)) {
        sideEffect = () => item.open();
      }
    }
    this.activeItemSubject.next(activeItem);
    sideEffect();
  }

  private get activeItem() {
    return this.activeItemSubject.getValue();
  }

  get activeItem$() {
    return this.activeItemSubject.asObservable();
  }

  get activePage$() {
    return this.activePageSubject.asObservable();
  }

  private containsQueryParam(url: string) {
    return url.includes('?') && !url.includes('?menuToggle=true') ? true : false;
  }

  public getClientId() {
    return this.clientIdSubject.asObservable();
  }

  public setClientId(clientId: string) {
    this.clientIdSubject.next(clientId);
  }

  private setActivePage(menuItems: MenuItem[], url: string) {
    const activePage = menuItems.find(item => {
      if (isLink(item)) {
        return url.includes(item.url);
      } else if (isDrawer(item)) {
        return item.links.some(item => url.includes((item as DrawerLink).url));
      }
      return false;
    });
    this.activePageSubject.next(activePage || null);
  }

  constructor(private router: Router) {
    // On intial load, set the active page based on the current URL
    this.menuItems$
      .pipe(
        filter(menuItems => menuItems.length > 0),
        take(1),
        map(menuItems => {
          this.setActivePage(menuItems, this.router.url);
        })
      )
      .subscribe();

    // On navigation, set the active page based on the new URL
    combineLatest([this.menuItems$, this.router.events])
      .pipe(
        filter(([menuItems, event]) => event instanceof NavigationStart && menuItems.length > 0),
        map(([menuItems, event]) => {
          const url = (event as NavigationStart).url;
          this.setActivePage(menuItems, url);
        })
      )
      .subscribe();
  }
}
