import { Component, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  template: `
    <mri-shared-page-message-box>
      <span class="header-text"> You do not have the necessary permissions to access the {{ appName }} Site </span>
    </mri-shared-page-message-box>
  `,
  styles: [
    `
      :host {
        height: 100%;
        background: no-repeat url('/assets/unauthorized-page/background.png');
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }
    `
  ]
})
export class UnauthorizedPageComponent {
  @HostBinding('attr.data-testid') testId = 'UnauthorizedPageComponent';
  appName = '';

  constructor(titleService: Title) {
    this.appName = titleService.getTitle();
  }
}
