import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializerFunc } from './models';
import { isDependencyTelemetryItem } from './telemetry-item-predicate';

export const whitelistResponseHeaderTelemetryInitializer =
  (headers: string[]): TelemetryInitializerFunc =>
  (item: ITelemetryItem) => {
    if (!isDependencyTelemetryItem(item) || item.baseData?.properties?.responseHeaders == null) return;

    const headerEntries = Object.entries(item.baseData?.properties?.responseHeaders);
    const allowed = headerEntries.filter(([key]) => headers.includes(key));
    if (allowed.length) {
      item.baseData.properties.responseHeaders = Object.fromEntries(allowed);
    } else {
      delete item.baseData.properties.responseHeaders;
    }
  };
