import {
  Event,
  Exception,
  ITelemetryItem,
  Metric,
  PageView,
  RemoteDependencyData
} from '@microsoft/applicationinsights-web';

const isTelementryItemOf = (type: string) => (item: ITelemetryItem) => item.baseType === type;

export const isDependencyTelemetryItem = isTelementryItemOf(RemoteDependencyData.dataType);
export const isEventTelemetryItem = isTelementryItemOf(Event.dataType);
export const isExceptionTelemetryItem = isTelementryItemOf(Exception.dataType);
export const isMetricTelemetryItem = isTelementryItemOf(Metric.dataType);
export const isPageViewTelemetryItem = isTelementryItemOf(PageView.dataType);
export const isAjaxDependencyTelemetryItem = (item: ITelemetryItem) =>
  isDependencyTelemetryItem(item) && item.baseData != null && item.baseData['type'] === 'Ajax';
