/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Inject, Injectable, Optional, Type } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
  RouterEvent,
  RoutesRecognized
} from '@angular/router';
import { generateW3CId } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights, IAutoExceptionTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, scan, shareReplay, switchMap } from 'rxjs/operators';
import { AppInsightsConfig } from './app-insights-config';
import { appVersionTelemetryInitializer } from './app-version-telemetry-initializer';
import { cloudRoleTelemetryInitializer } from './cloud-role-telemetry-initializer';
import { cloudflareResponseHeaderTelemetryInitializer } from './cloudflare-response-header-telemetry-initializer';
import { contentTypeResponseHeaderTelemetryInitializer } from './content-type-response-header-telemetry-initializer';
import { CustomProperties, TelemetryInitializer, TelemetryInitializerFunc } from './models';
import { NetworkInfoTelemetryInitializer } from './network-info-telemetry-initializer';
import { RouteContextTelemetryInitializer } from './route-context-telemetry-initializer';
import { TELEMETRY_INITIALIZER } from './telemetry-initializer-token';
import { whitelistResponseHeaderTelemetryInitializer } from './whitelist-response-header-telemetry-initialize';
import { getLastRoute, getNormalizedRoutePath } from './route-functions';

const bindTelemetryInitializer = (ti: TelemetryInitializer) => ti.enrichOrFilter.bind(ti);

function isNavigationEnd(event: Event): boolean {
  return event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExceptionTelementry(value: any): value is IExceptionTelemetry {
  return value && value.exception != null;
}

@Injectable({ providedIn: 'root' })
export class AppInsightsService implements ErrorHandler {
  /**
   * Configuration that will disable as much of App Insights functionality as is possible
   */
  static disabledConfig: Partial<AppInsightsConfig> = {
    enableAutoRouteTracking: false,
    enableCorsCorrelation: false,
    enableCustomPageViewEvent: false,
    enableSessionStorageBuffer: true,
    enableTelemetry: false,
    disableAjaxTracking: true,
    disableCorrelationHeaders: true,
    disableExceptionTracking: true,
    disableFetchTracking: true,
    disableFlushOnBeforeUnload: true,
    disableDataLossAnalysis: true,
    disableTelemetry: true
  };

  config: AppInsightsConfig;
  svc: ApplicationInsights;

  private _enabled = true;
  get enabled() {
    return this._enabled;
  }

  private isInitDone = false;

  constructor(
    config: AppInsightsConfig,
    private router: Router,
    private routeContextTelemetryInitializer: RouteContextTelemetryInitializer,
    private networkInfoTelemetryInitializer: NetworkInfoTelemetryInitializer,
    @Optional() @Inject(TELEMETRY_INITIALIZER) telemetryInitializers: TelemetryInitializer[]
  ) {
    this.config = this.createConfig(config, telemetryInitializers || []);
    this.svc = new ApplicationInsights({
      config: this.config
    });
  }

  handleError(error: Error | IAutoExceptionTelemetry): void {
    if (!this.enabled) return;

    const [exception, properties] = this.getExceptionTelemetry(error) ?? [];
    if (!exception) {
      return;
    }

    try {
      // todo: buffer errors that arrive before `loadAppInsights` has been called
      this.svc.appInsights.trackException(exception, properties);
    } catch (error) {
      console.error('Error thrown trying to call `ApplicationInsights.trackException`', error);
    }
  }

  init() {
    if (this.isInitDone) return;

    this.svc.loadAppInsights();

    // keep a snapshot of the decision to be enabled or not at the point of initialization
    // this is in case a consumer changes the value of our `config` during the course of app running
    this._enabled = !this.getIsDisabled(this.config);

    if (this.enabled) {
      try {
        this.config.telemetryInitializers?.forEach(ti => this.svc.addTelemetryInitializer(ti));
        this.trackPageVisits();
        this.watchUserLogin();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.handleError(error);
      }
    }

    this.isInitDone = true;
  }

  /**
   * Execute the `action` function supplied, but only telemetry is enabled
   * @param action action that will receive the instance of the ApplicationInsights service
   */
  whenEnabled(action: (ai: ApplicationInsights) => void) {
    if (!this.enabled) return;

    action(this.svc);
  }

  private createConfig(config: AppInsightsConfig, telemetryInitializers: TelemetryInitializer[]): AppInsightsConfig {
    const disableTelemetry = this.getIsDisabled(config);

    const instrumentationKey = config.instrumentationKey ?? '';
    let finalConfigs: AppInsightsConfig = {
      autoRun: true,
      autoSetAuthenticatedUserContext: config.loginEvents != null && config.userSelector != null,
      enableCorsCorrelation: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enableNetworkInfoTracking: (navigator as any)?.connection != null,
      enableCustomPageViewEvent: true,
      enableRouterChangeTracking: true,
      enableRouterContextTracking: true,
      ...config,
      enableResponseHeaderTracking:
        config.enableAjaxResponseLengthTracking || config.enableCloudflareResponseTracking
          ? true
          : config.enableResponseHeaderTracking,
      disableTelemetry,
      enableTelemetry: !disableTelemetry,
      instrumentationKey
    };

    if (disableTelemetry) {
      finalConfigs = {
        ...finalConfigs,
        ...AppInsightsService.disabledConfig
      };
    }
    finalConfigs.telemetryInitializers = this.getTelemetryInitializers(finalConfigs, telemetryInitializers);

    return finalConfigs;
  }

  private getExceptionTelemetry(
    error: Error | IAutoExceptionTelemetry
  ): [IExceptionTelemetry, CustomProperties] | undefined {
    const { errorSelector } = this.config;
    if (!errorSelector) {
      return [{ exception: error }, {}];
    }

    const e = errorSelector(error);

    if (e == null) {
      return undefined;
    } else if (Array.isArray(e)) {
      const [ex, properties] = e;
      return isExceptionTelementry(ex) ? [ex, properties] : [{ exception: ex }, properties];
    } else {
      return isExceptionTelementry(e) ? [e, {}] : [{ exception: e }, {}];
    }
  }

  private getIsDisabled({ disableTelemetry, enableTelemetry, instrumentationKey }: AppInsightsConfig) {
    return !instrumentationKey || disableTelemetry === true || enableTelemetry === false;
  }

  private getTelemetryInitializers(config: AppInsightsConfig, telemetryInitializers: TelemetryInitializer[]) {
    const prefixInitializers: TelemetryInitializerFunc[] = [];
    const postfixInitializers: TelemetryInitializerFunc[] = [];

    if (config.appVersion) {
      prefixInitializers.push(appVersionTelemetryInitializer(config.appVersion));
    }
    if (config.cloudRoleName) {
      prefixInitializers.push(cloudRoleTelemetryInitializer(config.cloudRoleName));
    }

    if (config.enableAjaxResponseLengthTracking) {
      prefixInitializers.push(contentTypeResponseHeaderTelemetryInitializer);
    }
    if (config.enableCloudflareResponseTracking) {
      prefixInitializers.push(cloudflareResponseHeaderTelemetryInitializer);
    }
    if (config.enableAjaxResponseLengthTracking || config.enableCloudflareResponseTracking) {
      // remove response headers from telemetry as we've already capture the headers we need above
      postfixInitializers.push(whitelistResponseHeaderTelemetryInitializer([]));
    }

    if (config.enableRouterContextTracking) {
      prefixInitializers.push(bindTelemetryInitializer(this.routeContextTelemetryInitializer));
    }
    if (config.enableNetworkInfoTracking) {
      prefixInitializers.push(bindTelemetryInitializer(this.networkInfoTelemetryInitializer));
    }

    return [
      ...prefixInitializers,
      ...(config.telemetryInitializers || []),
      ...telemetryInitializers.map(bindTelemetryInitializer),
      ...postfixInitializers
    ];
  }

  private trackPageEnds(pageEnd$: Observable<RouterEvent>) {
    const emptyRoute = { url: '', previousUrl: '' };
    const pageViewInfo$ = pageEnd$.pipe(scan(({ url: previousUrl }, { url }) => ({ url, previousUrl }), emptyRoute));
    pageViewInfo$.forEach(({ url, previousUrl }) => {
      const properties: { [key: string]: string } = previousUrl
        ? {
            refUri: previousUrl
          }
        : {
            refUri: ''
          };
      this.svc.stopTrackPage(url, undefined, properties);
    });
  }

  private trackPageStarts(pageStart$: Observable<RoutesRecognized>) {
    pageStart$.forEach(evt => {
      const lastRoute = getLastRoute(evt.state.root);
      const routePath = getNormalizedRoutePath(lastRoute.pathFromRoot);
      this.svc.context.telemetryTrace.traceID = generateW3CId();
      this.svc.context.telemetryTrace.name = routePath || evt.url;
      this.svc.startTrackPage(evt.url);
      if (this.config.enableCustomPageViewEvent) {
        this.svc.trackEvent({ name: 'page-view' });
      }
    });
  }

  private trackPageVisits() {
    const { enableAutoRouteTracking, enableRouterChangeTracking } = this.config;
    if (!enableRouterChangeTracking) {
      return;
    }
    if (enableAutoRouteTracking && enableRouterChangeTracking) {
      throw new Error('Enabling both `enableAutoRouteTracking` and `enableRouterChangeTracking` in not allowed');
    }

    const pageStart$ = this.router.events.pipe(
      this.instanceOfType(RoutesRecognized),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const navEnd$ = this.router.events.pipe(
      switchMap(event => (isNavigationEnd(event) ? of(event as RouterEvent) : EMPTY))
    );
    const pageEnd$ = pageStart$.pipe(switchMap(_ => navEnd$));

    this.trackPageStarts(pageStart$);
    this.trackPageEnds(pageEnd$);
  }

  instanceOfType<T>(type: Type<T>) {
    return (source$: Observable<unknown>) =>
      source$.pipe(switchMap(value => (value instanceof type ? of(value as T) : EMPTY)));
  }

  private watchUserLogin() {
    const { autoSetAuthenticatedUserContext, loginEvents, userSelector } = this.config;
    if (!autoSetAuthenticatedUserContext) {
      return;
    }
    if (!loginEvents || !userSelector) {
      throw new Error('`loginEvents` and `userSelector` mandatory when `autoSetAuthenticatedUserContext` is true');
    }

    const authenticatedUserInfo$ = loginEvents.pipe(
      filter(value => value != null),
      map(userSelector),
      filter(userInfo => !!userInfo?.userId)
    );

    authenticatedUserInfo$.forEach(({ userId, accountId }) => {
      this.svc.setAuthenticatedUserContext(userId, accountId, true);
    });
  }
}
