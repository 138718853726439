import trimEnd from 'lodash-es/trimEnd';

export interface AgWidgetConfigData {
  /**
   * Base url of the AG API that the AG Bar component will fetch/save it's data
   *
   * EG: 'https://qa-agapi-rel.redmz.mrisoftware.com'
   */
  baseApiUrl: string;
}

const defaults: AgWidgetConfigData = {
  baseApiUrl: ''
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
export interface AgWidgetConfig extends AgWidgetConfigData {}

export class AgWidgetConfig {
  get apiDomain() {
    return this.baseApiUrl ? new URL(this.baseApiUrl).hostname : 'localhost';
  }

  // private get IsDeveloperServer() {
  //   return this.apiDomain.includes('local');
  // }

  constructor(json?: AgWidgetConfigData) {
    json = json ?? defaults;
    const baseApiUrl = trimEnd(json.baseApiUrl, '/').trim();
    Object.assign(this, defaults, json, { baseApiUrl });
  }
}
