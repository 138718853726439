import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializerFunc } from './models';
import { isAjaxDependencyTelemetryItem, isPageViewTelemetryItem } from './telemetry-item-predicate';

export const cloudflareResponseHeaderTelemetryInitializer: TelemetryInitializerFunc = (item: ITelemetryItem) => {
  if (
    (!isPageViewTelemetryItem(item) && !isAjaxDependencyTelemetryItem(item)) ||
    item.baseData?.properties?.responseHeaders == null
  ) {
    return;
  }

  const cloudflareChallengeResponse = item.baseData.properties.responseHeaders['cf-mitigated'] === 'challenge';
  const cloudflareCacheHit = item.baseData.properties.responseHeaders['cf-cache-status'] === 'HIT';
  if (cloudflareChallengeResponse || cloudflareCacheHit) {
    Object.assign(item.baseData.properties, { cloudflareChallengeResponse, cloudflareCacheHit });
  }
};
