import { Injectable } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { PageViewTelemetryEnrichmentInitializer } from './page-view-telemetry-enrichment-initializer';

@Injectable({ providedIn: 'root' })
export class RouteContextTelemetryInitializer extends PageViewTelemetryEnrichmentInitializer {
  protected getData(_item: ITelemetryItem) {
    const { params, queryParams } = this.currentRoute;
    const result: { [key: string]: unknown } = {
      routePath: this.currentRoutePath
    };
    if (Object.keys(params).length) {
      result.routeParams = params;
    }
    if (Object.keys(queryParams).length) {
      result.routeQueryParams = queryParams;
    }
    return result;
  }
}
