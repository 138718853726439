import trimEnd from 'lodash-es/trimEnd';

export interface AskAgoraConfigData {
  /**
   * Base url of the Ask Agora API that the Ask Agora component will perform chat functionality.
   *
   * EG: 'https://askagorainsights-na-dev.devtest.mrisoftware.com'
   */
  baseApiUrl: string;
  /**
   * The source of the assets for the Ask Agora component.
   */
  assetSource?: string;
  /**
   * The current user's client ID.
   */
  clientId?: string;
  /**
   * The offset of the widget from the left side of the screen.
   */
  widgetOffset: number;

  isActivated?: boolean;

  showIcon?: boolean;

  popupClose?: () => void;
}

const defaults: AskAgoraConfigData = {
  baseApiUrl: '',
  widgetOffset: 0
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
export interface AskAgoraConfig extends AskAgoraConfigData {}

export class AskAgoraConfig {
  constructor(json?: AskAgoraConfigData) {
    json = json ?? defaults;

    Object.assign(this, defaults, json, {
      baseApiUrl: trimEnd(json.baseApiUrl, '/').trim(),
      assetSource: json.assetSource?.trim(),
      clientId: json.clientId?.trim(),
      isActivated: json.isActivated ?? false,
      showIcon: json.showIcon ?? true,
      popupClose: json.popupClose
    });
  }
}
