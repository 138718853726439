import { InjectionToken } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryInitializer } from './models';

export const TELEMETRY_INITIALIZER = new InjectionToken<TelemetryInitializer>('AppInsights Telemetry Initializer');

/**
 * Convenience base class to create a telemetry initializer that will
 * determine whether or not to send the telemetry item to app insights
 * @example
 * providers: [{
 *   provide: TELEMETRY_INITIALIZER,
 *   useClass: YourTelemetryFilterInitializer,
 *   multi: true
 * }]
 */
export abstract class TelemetryFilterInitializer implements TelemetryInitializer {
  enrichOrFilter(item: ITelemetryItem): boolean | void {
    return this.shouldSend(item);
  }

  /**
   * Determine whether telementry item should be sent to app insights
   * @param item telemetry item to test
   * Return `true` to send the item `false` to NOT send the item
   */
  protected abstract shouldSend(item: ITelemetryItem): boolean;
}
