import { ActivatedRouteSnapshot } from '@angular/router';

export const getNormalizedRoutePath = (routes: ActivatedRouteSnapshot[]) =>
  [''].concat(routes.map(r => r.routeConfig?.path).filter((p): p is string => !!p)).join('/');

export function getLastRoute(route: ActivatedRouteSnapshot) {
  let current = route;
  while (current.firstChild) {
    current = current.firstChild;
  }
  return current;
}
