<mri-shared-drawer
  [hideHeader]="true"
  [position]="'left'"
  [isOpen]="this.isOpen"
  [clickOverlayToClose]="true"
  [overlayContainerOnly]="true"
  (closeDrawer)="close()"
>
  <div *mriSharedDrawerBody id="menuWindow" class="menuWindow">
    <ng-container *ngIf="this.activeItem as contents">
      <div id="subMenuWindow" class="subMenuWindow">
        <h4 class="mri-heading mri-heading--4 mri-header menu-header">{{ contents.header }}</h4>
        <div class="MenuColumn">
          <div class="menuGroup">
            <ng-container
              *ngFor="let item of contents.links"
              [ngTemplateOutlet]="isSection(item) ? drawerSection : drawerLink"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mri-shared-drawer>

<ng-template #drawerSection let-section>
  <div class="menuItem">{{ section.text }}</div>
  <div class="subMenuGroup">
    <ng-container
      *ngFor="let link of section.links"
      [ngTemplateOutlet]="drawerLink"
      [ngTemplateOutletContext]="{ $implicit: link }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #drawerLink let-link>
  <div class="menuItem menuAction">
    <a class="mri-link" role="link" [title]="link.text" (click)="routeToUrl(link)">
      {{ link.text }}
    </a>
  </div>
</ng-template>
